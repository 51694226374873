import { post, get } from '../request/http'
// 登录接口
export const getLogin = res => post('/v1/evaluation/common/user/login', res);
// 退出接口
export const getLogOut = res => get('/v1/evaluation/common/user/logout', res);
// 注册接口
export const getRegister = res => post('/v1/evaluation/common/user/sign-up-one', res);
// 发送验证码接口
export const getSendCode = res => get('/v1/evaluation/common/sendCode', res);
// 完善信息接口
export const getPerfectInfo = res => post('/v1/evaluation/common/user/sign-up-two', res);
// 机构/学校接口
export const getSchool = res => get('/v1/evaluation/common/institution/all', res);
// 班级接口
export const getTema = res => get(`/v1/evaluation/common/institution/${res}/classes`);
// 上传图片接口
export const getUploadImages = res => post(`/v1/evaluation/common/uploadImagesTimeBase`, res);
// 我的测评接口
export const getMyEvaluation = res => get(`/v1/evaluation/student/${res}/items`);
// 个人信息接口
export const getUserInfo = res => get(`/v1/evaluation/student/${res}/info`);
// 测评列表接口
export const getEvaluationList = res => get(`v1/evaluation/common/items/${res}/details-list`);
// 热门测评接口
export const getHotEvaluationList = res => get(`/v1/evaluation/common/items/hot`, res);
// 测评详情接口
export const getEvaluationDetail = res => get(`/v1/evaluation/common/items/${res}/details`);
// 测评须知接口
export const getEvaluationNotice = res => get(`/v1/evaluation/student/evaluation/${res}/subject-ids`);
// 上一题下一题接口
export const getQuestion = res => get(`/v1/evaluation/student/evaluation/${res.code}/subject/${res.id}/get`);
// 答题记录接口
export const getQuestionHistory = res => get(`/v1/evaluation/student/${res.id}/evaluation/${res.code}/subject/list-options`);
// 提交每道题接口
export const getSubQuestionItem = res => post(`/v1/evaluation/student/${res.id}/subject/${res.code}/submit`, res);
// 确认完成接口
export const getConfirmSub = res => post(`/v1/evaluation/student/${res.id}/evaluation/${res.code}/submit`, {});
// 测评报告接口
export const getEvaluationReport = res => get(`/v1/evaluation/student/${res.id}/evaluation/${res.code}/view`);
// 提交星星接口
export const getSubScore = res => post(`/v1/evaluation/student/evaluation/${res.code}/comment`, res);
// token登录接口
export const getLoginToken = res => post(`/v1/evaluation/common/user/token-login`, res);
// 获奖分享code
export const getEncrypt = res => post(`/v1/evaluation/student/share/getEncrypt`, res);
// 获奖分享code
export const getReport = res => get(`/v1/evaluation/student/share/getReport/${res}`);
// 获奖分享code
export const getLoadUrl = res => get(`/v1/evaluation/student/share/loadUrl/${res.type}/${res.encrypt}`);
// 综合测评报告
export const getComprehensive = res => get(`/v1/evaluation/student/${res.studentId}/evaluation/comprehensive/view`);
// 测评详情接口
export const getEvaluation = res => get(`/v1/evaluation/common/items/evaluation/${res}/details`);
// 批量答题
export const getBatchSubmit = res => post(`/v1/evaluation/student/${res.evaluationCode}/subject/batch-submit`, res);
// 确认完成接口
export const getConfirmSubmit = res => post(`/v1/evaluation/student/${res.id}/evaluation/${res.code}/submit`, res);